import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ImpressumPage = () => (
    <Layout>
        <Seo title="Impressum" />
        <section className="section">
            <div className="container is-max-desktop">
                <div className="content">
                    <h1 className="title is-1 mb-6">Impressum</h1>

                    <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>

                    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                    <p>Maxi Benndorf<br />
                        Physiotherapie mitbewegt<br />
                        Holbeinstr. 24<br />
                        04229 Leipzig</p>

                    <h2>Kontakt</h2>
                    <p>Telefon: +4934123159742<br />
                        E-Mail: mitbewegt@gmx.de</p>

                    <h2>Steuernummer</h2>
                    <p>232-205-01965</p>

                    <h2>Aufsichtsbeh&ouml;rde</h2>
                    <p>Leipziger Gesundheitsamt<br />
                        Friedrich-Ebert-Stra&szlig;e 19A<br />
                        04109 Leipzig<br />
                        Deutschland</p>
                    <p><a href="https://www.leipzig.de/jugend-familie-und-soziales/gesundheit/" target="_blank" rel="noopener noreferrer">https://www.leipzig.de/jugend-familie-und-soziales/gesundheit/</a></p>

                    <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
                    <p>Berufsbezeichnung:<br />
                        Staatlich anerkannte Physiotherapeutin</p>
                    <p>Zust&auml;ndige Kammer:<br />
                        Deutscher Verband f&uuml;r Physiotherapie, Landesverband Sachsen e.V.<br />
                        Hans-B&ouml;heim-Stra&szlig;e 5<br />
                        01309 Dresden</p>
                    <p>Verliehen in:<br />
                        Deutschland</p>
                    <p>Es gelten folgende berufsrechtliche Regelungen:</p>
                    <p>Physiotherapie (MPhG)<br />
                        einsehbar unter: <a href="https://www.gesetze-im-internet.de/mphg/BJNR108400994.html" target="_blank" rel="noopener noreferrer">https://www.gesetze-im-internet.de/mphg/BJNR108400994.html</a></p>
                    <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
                    <p><strong>Name und Sitz des Versicherers:</strong><br />
                        Continentale Sachversicherung AG<br />
                        Ruhrallee 92<br />
                        44139 Dortmund</p>
                    <p><strong>Geltungsraum der Versicherung:</strong><br />Deutschland</p>

                    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                    <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
                </div>
            </div>
        </section>

    </Layout>
)

export default ImpressumPage
